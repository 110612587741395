<script lang="ts" setup>
import { useSessionStorage } from '@vueuse/core'
import { OuiClose } from 'oui-kit'
import { ref } from 'vue'
import { useI18n } from 'vue-i18n'
import './app-banner.styl'

const showBanner = useSessionStorage('app-banner', true)
const show = ref(false)
setTimeout(() => show.value = true, 1000)

const { locale } = useI18n()
</script>

<template>
  <div class="app-banner" :class="{ 'app-banner-show': show && showBanner }">
    <div class="app-banner-inner grid-container">
      <a href="https://xml2invoice.com?ref=banner" target="_blank">
        <template v-if="locale === 'de'">
          E-Rechnung? ZUGFeRD? EN 16931? Factur-X? XRechnung? XML? 2025 wird die elektronische Rechnung für viele Pflicht. Mit unserem Tool <b>XML2Invoice.com</b> wird das Lesen, Erstellen und Bezahlen von E-Rechnungen zum Kinderspiel.
          <br><br>
          <span> → Jetzt kostenlos und sicher direkt in deinem Browser testen!</span>
        </template>
        <template v-else>
          E-Invoicing? ZUGFeRD? EN 16931? Factur-X? XML?
          In 2025, electronic invoicing will be mandatory for many. With our tool, reading, creating and paying e-invoices will be a breeze.
          <br><br>
          <span>→ Test it now for free and securely directly in your browser!</span>
        </template>
      </a>
      <button @click="showBanner = false">
        <OuiClose />
      </button>
    </div>
  </div>
</template>
